import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ModalController, AlertController, NavController, LoadingController, Platform} from '@ionic/angular';
import {GroupMemberEditPage} from '../../components/group-member-edit/group-member-edit.page';
import {AuthProvider} from '../../../providers/auth';
import {ApiProvider} from '../../../providers/api';
import * as Query from '../../../graphql/queries';
import * as Mutation from '../../../graphql/mutations';
import {StorageService} from '../../services/storage.service';
import * as Custom from '../../../custom/customQueries';


@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.page.html',
  styleUrls: ['./user-info.page.scss'],
})
export class UserInfoPage implements OnInit {
  groupmemberid: string = '';
  groupmemberDetail: string = '';
  teamMemberData: any = [];
  teamData: any = [];
  corporateId: string = '';
  corporate: string = '';
  groupId: string = '';
  group: string = '';

  userId = '';
  loginUser = null;
  loginUserId = '';
  isLoginGroupMember = false;
  loading = false;
  loader: any;
  viewModalFlag: boolean = false;

  constructor(
    public router: Router,
    public modalController: ModalController,
    private loadingController: LoadingController,
    public storageService: StorageService,
    private APIPrv: ApiProvider,
    private platform: Platform
  ) {
  }


  async ngOnInit() {
    this.loading = true;
    const isLogin = await this.storageService.loginCheck();
    if (!isLogin) {
      return;
    }
    await this.presentLoading();
    try {
      await this.loginUserInfo();
      await this.getGroupMember(this.loginUserId);

      this.isLoginGroupMember = this.loginUserId === this.groupmemberid;
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
    if (this.loader) {
      this.loader.dismiss();
    }
  }

  async loginUserInfo() {
    const userId = await this.storageService.getUserId();
    const groupId = await this.storageService.getGroupId();
    this.loginUserId = `${userId}#${groupId}`;
    this.corporateId = groupId.split('#')[1];
  }

  async getGroupMember(id) {
    if (!id) {
      this.dismiss();
    } else {
      const groupId = await this.storageService.getGroupId();
      this.groupId = groupId;
      this.groupmemberid = id;


      try {
        await this.getCorporate();

        // グループメンバー情報取得
        const getGroupMember = await this.APIPrv.graphqlLamda(Custom.getGroupMemberDetail, {id: id});
        this.groupmemberDetail = getGroupMember['data']['getGroupMember'];
        this.group = this.groupmemberDetail['group']
        const teamMemberData = this.groupmemberDetail['teamMembers'].items;

        // チーム情報情報取得
        this.teamData = [];
        // チーム情報の取得
        let defaultTeam = null;
        const teamData = [];
        for (const tm of teamMemberData) {
          if (tm.team.isDefault === 1) {
            defaultTeam = tm.team;
          } else {
            teamData.push(tm.team);
          }
        }
        this.teamData.push(defaultTeam);
        this.teamData = this.teamData.concat(teamData);

      } catch (e) {
        console.log(e);
      }
    }
  }

  async getCorporate() {
    const corporate = await this.APIPrv.graphqlLamda(Query.getCorporate, {id: this.corporateId});
    this.corporate = corporate['data']['getCorporate']
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async presentLoading() {
    this.loader = await this.loadingController.create({
      cssClass: 'my-custom-class',
    });
    this.loader.present();
  }
}
