import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  }, {
    path: '',
    redirectTo: 'login-id-input',
    pathMatch: 'full'
  }, {
    path: 'login-mail-input',
    loadChildren: () => import('./pages/login-mail-input/login-mail-input.module').then(m => m.LoginMailInputPageModule)
  }, {
    path: 'login-id-input',
    loadChildren: () => import('./pages/login-id-input/login-id-input.module').then(m => m.LoginIdInputPageModule)
  }, {
    path: 'update-version',
    loadChildren: () => import('./pages/update-version/update-version.module').then(m => m.UpdateVersionPageModule)
  }, {
    path: 'update-terms',
    loadChildren: () => import('./pages/update-terms/update-terms.module').then(m => m.UpdateTermsPageModule)
  }, {
    path: 'login-auth',
    loadChildren: () => import('./pages/login-auth/login-auth.module').then(m => m.LoginAuthPageModule)
  }, {
    path: 'notification-report',
    loadChildren: () => import('./components/notification-report/notification-report.module').then(m => m.NotificationReportPageModule)
  }, {
    path: 'mailing-edit-questionnaire',
    loadChildren: () => import('./components/mailing-edit-questionnaire/mailing-edit-questionnaire.module').then(m => m.MailingEditQuestionnairePageModule)
  }, {
    path: 'group-member-list',
    loadChildren: () => import('./pages/group-member-list/group-member-list.module').then(m => m.GroupMemberListPageModule)
  }, {
    path: 'contact-confirm',
    loadChildren: () => import('./pages/contact-confirm/contact-confirm.module').then(m => m.ContactConfirmPageModule)
  }, {
    path: 'contact-input',
    loadChildren: () => import('./pages/contact-input/contact-input.module').then(m => m.ContactInputPageModule)
  }, {
    path: 'mailing-edit-destination',
    loadChildren: () => import('./components/mailing-edit-destination/mailing-edit-destination.module').then(m => m.MailingEditDestinationPageModule)
  }, {
    path: 'mailing-image-preview',
    loadChildren: () => import('./components/mailing-image-preview/mailing-image-preview.module').then(m => m.MailingImagePreviewPageModule)
  }, {
    path: 'account-notification-list',
    loadChildren: () => import('./components/account-notification-list/account-notification-list.module').then(m => m.AccountNotificationListPageModule)
  }, {
    path: 'mailing-list/:mode',
    loadChildren: () => import('./pages/mailing-list/mailing-list.module').then(m => m.MailingListPageModule)
  }, {
    path: 'mailing-edit-body',
    loadChildren: () => import('./components/mailing-edit-body/mailing-edit-body.module').then(m => m.MailingEditBodyPageModule)
  }, {
    path: 'terms',
    loadChildren: () => import('./components/terms/terms.module').then(m => m.TermsPageModule)
  }, {
    path: 'update-terms-privacypolicy',
    loadChildren: () => import('./components/update-terms-privacypolicy/update-terms-privacypolicy.module').then(m => m.UpdateTermsPrivacypolicyPageModule)
  }, {
    path: 'group-member-add',
    loadChildren: () => import('./components/group-member-add/group-member-add.module').then(m => m.GroupMemberAddPageModule)
  }, {
    path: 'group-member-edit',
    loadChildren: () => import('./components/group-member-edit/group-member-edit.module').then(m => m.GroupMemberEditPageModule)
  }, {
    path: 'mailing-member-answer-list',
    loadChildren: () => import('./components/mailing-member-answer-list/mailing-member-answer-list.module').then(m => m.MailingMemberAnswerListPageModule)
  },
  {
    path: 'team-manage',
    loadChildren: () => import('./pages/team-manage/team-manage.module').then(m => m.TeamManagePageModule)
  },
  {
    path: 'team-manage-member-list/:teamid',
    loadChildren: () => import('./pages/team-manage-member-list/team-manage-member-list.module').then(m => m.TeamManageMemberListPageModule)
  },
  {
    path: 'user-guide',
    loadChildren: () => import('./pages/user-guide/user-guide.module').then(m => m.UserGuidePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'direct-message-add',
    loadChildren: () => import('./components/direct-message-add/direct-message-add.module').then(m => m.DirectMessageAddPageModule)
  },
  {
    path: 'mailing-remind',
    loadChildren: () => import('./components/mailing-remind/mailing-remind.module').then(m => m.MailingRemindPageModule)
  },
  {
    path: 'mailing-result-questionnaire',
    loadChildren: () => import('./components/mailing-result-questionnaire/mailing-result-questionnaire.module').then(m => m.MailingResultQuestionnairePageModule)
  },
  {
    path: 'login-passcode',
    loadChildren: () => import('./pages/login-passcode/login-passcode.module').then(m => m.LoginPasscodePageModule)
  },
  {
    path: 'team-member-list-general',
    loadChildren: () => import('./components/team-member-list-general/team-member-list-general.module').then(m => m.TeamMemberListGeneralPageModule)
  },
  {
    path: 'team-info-member-list/:teamid',
    loadChildren: () => import('./pages/team-info-member-list/team-info-member-list.module').then(m => m.TeamInfoMemberListPageModule)
  },
  {
    path: 'team-info',
    loadChildren: () => import('./components/team-info/team-info.module').then(m => m.TeamInfoPageModule)
  },
  {
    path: 'account-notification-setting',
    loadChildren: () => import('./pages/account-notification-setting/account-notification-setting.module').then(m => m.AccountNotificationSettingPageModule)
  },
  {
    path: 'mailing-member-read-list/:id',
    loadChildren: () => import('./pages/mailing-member-read-list/mailing-member-read-list.module').then(m => m.MailingMemberReadListPageModule)
  },
  {
    path: 'direct-message-list',
    loadChildren: () => import('./pages/direct-message-list/direct-message-list.module').then(m => m.DirectMessageListPageModule)
  },
  {
    path: 'direct-message-chat/:id/:name',
    loadChildren: () => import('./pages/direct-message-chat/direct-message-chat.module').then(m => m.DirectMessageChatPageModule)
  },
  {
    path: 'team-add',
    loadChildren: () => import('./components/team-add/team-add.module').then(m => m.TeamAddPageModule)
  },
  {
    path: 'team-edit',
    loadChildren: () => import('./components/team-edit/team-edit.module').then(m => m.TeamEditPageModule)
  },
  {
    path: 'team-name-edit',
    loadChildren: () => import('./components/team-name-edit/team-name-edit.module').then(m => m.TeamNameEditPageModule)
  },
  {
    path: 'team-member-list',
    loadChildren: () => import('./components/team-member-list/team-member-list.module').then(m => m.TeamMemberListPageModule)
  },
  {
    path: 'team-member-add',
    loadChildren: () => import('./components/team-member-add/team-member-add.module').then(m => m.TeamMemberAddPageModule)
  },
  {
    path: 'group-member-detail',
    loadChildren: () => import('./pages/group-member-detail/group-member-detail.module').then(m => m.GroupMemberDetailPageModule)
  },
  {
    path: 'mailing-add/:id',
    loadChildren: () => import('./pages/mailing-add/mailing-add.module').then(m => m.MailingAddPageModule)
  },
  {
    path: 'mailing-detail/:id',
    loadChildren: () => import('./pages/mailing-detail/mailing-detail.module').then(m => m.MailingDetailPageModule)
  },
  {
    path: 'group-member-bulk',
    loadChildren: () => import('./pages/group-member-bulk/group-member-bulk.module').then(m => m.GroupMemberBulkPageModule)
  },
  {
    path: 'notification-detail',
    loadChildren: () => import('./components/notification-detail/notification-detail.module').then(m => m.NotificationDetailPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ERRORPageModule)
  },
  {
    path: 'guide',
    loadChildren: () => import('./components/guide/guide.module').then( m => m.GuidePageModule)
  },
  {
    path: 'force-update',
    loadChildren: () => import('./pages/force-update/force-update.module').then( m => m.ForceUpdatePageModule)
  },
  {
    path: 'user-info',
    loadChildren: () => import('./components/user-info/user-info.module').then( m => m.UserInfoPageModule)
  },
  {
    path: 'app/:path/:id',
    loadChildren: () => import('./pages/app-redirect/app-redirect.module').then( m => m.AppRedirectPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
