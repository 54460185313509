import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController, NavParams, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NotificationReportPage } from '../notification-report/notification-report.page';

import { UpdateTeamMemberInput } from '../../../API';
import {ApiProvider} from "../../../providers/api";
import * as Mutation from '../../../graphql/mutations';
import { NetworkService } from 'src/app/services/network.service';
import { Network } from '@capacitor/network';
import { StorageService } from '../../services/storage.service';
import * as Custom from '../../../custom/customQueries';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  teamView: number;
  teamMemberId: string;
  teamid: string = '';
  networkStatus = true;
  listener: any;
  loader: any;
  modalEnd: any;

  constructor(
    private router: Router,
    private popoverCtrl: PopoverController,
    private modalController: ModalController,
    private netService: NetworkService,
    private loadingController: LoadingController,
    private navParams: NavParams,
    public storageService: StorageService,
    private APIPrv: ApiProvider,
  ) {
  }

  ngOnInit() {
    this.teamid = this.navParams.data.teamId;
    this.teamView = this.navParams.data.teamView;
    this.teamMemberId = this.navParams.data.teamMemberId;
  }

  async ionViewDidEnter() {
    this.networkStatus = await this.netService.getStatus();
    await this.netService.startListenNetwork();
    this.listener = Network.addListener('networkStatusChange', async (status) => {
      this.networkStatus = status.connected;
    });
  }

  ionViewWillLeave() {
    this.netService.stopListenNetwork();
  }

  isOnline() {
    return !this.networkStatus;
  }

  async NotificationReportPageModal() {
    const modal = await this.modalController.create({
      component: NotificationReportPage,
      componentProps: {
        teamId: this.teamid,
      }
    });

    modal.onDidDismiss()
      .then(() => {
        this.modalEnd();
      });

    await modal.present();
  }

  async teamViewChange() {
    await this.presentLoading();
    const isLogin = await this.storageService.loginCheck();
    if (!isLogin) {
      return;
    }
    try {
      // TeamView StatusをToggleする （0: Off、1: On）
      if (this.teamView === 0) {
        this.teamView = 1;
      } else {
        this.teamView = 0;
      }

      // TeamViewをアップデートする
      const input: UpdateTeamMemberInput = {
        id: this.teamMemberId,
        teamView: this.teamView,
      };

      await this.APIPrv.graphql(Custom.updateTeamMember, { input });

    } catch (e) {
      console.log(e);
    }

    if (this.loader) {
      this.loader.dismiss();
    }

    this.modalEnd();

    // Popoverを閉じる
    this.popoverCtrl.dismiss({
      status: this.teamView,
    });
  }

  async dismiss() {
    this.popoverCtrl.dismiss(true);
    await this.NotificationReportPageModal();
  }

  async presentLoading() {
    this.loader = await this.loadingController.create({
      cssClass: 'my-custom-class',
    });
    this.loader.present();
  }

}
