import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import { LOADER_WAIT_TIME } from 'src/app/const/loader'

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor(
    public loadingController: LoadingController
  ) {
  }

  isLoading: boolean = false;

  create() {
    this.loadingController.create({
      cssClass: 'my-custom-class'
    }).then((response) => {
      response.present();
    });
  }

  presentLoading() {
    this.isLoading = true;
    setTimeout(() => {
      if (this.isLoading === true) {
        this.create();
      }
    }, LOADER_WAIT_TIME);
  }

  dismiss() {
    this.isLoading = false;
    this.loadingController.getTop().then(item => {
      if (item) {
        this.loadingController.dismiss();
      } else {
        // Loading表示が検知できなくて切れないときは1.5秒後にもう一回Loading表示を切る処理をする
        this.setDismissLoadingBar(1500);
      }
    });
  }

  viewEnterDismiss(flag: boolean) {
    // loading中だったらReturnする
    if (!flag) {
      return
    }

    this.setDismissLoadingBar(1500);
  }

  setDismissLoadingBar(time: number) {
    setTimeout(() => {
      this.loadingController.getTop().then(item => {
        if (item) this.loadingController.dismiss();
      })
    }, time)
  }
}
