import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {MenuController, LoadingController} from '@ionic/angular';
import {AlertController} from '@ionic/angular';
import {NavController} from '@ionic/angular';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AuthProvider} from '../../../providers/auth';
import {ApiProvider} from "../../../providers/api";
import * as Query from '../../../graphql/queries';
import * as Mutation from '../../../graphql/mutations';
import {StorageService} from '../../services/storage.service';
import * as Custom from '../../../custom/customQueries';
import {NetworkService} from 'src/app/services/network.service';
import {Network} from '@capacitor/network';
import { PushNoticeProvider } from '../../../providers/push-notice';
import {DeleteUserDeviceInput} from "../../../API";
import {LoaderService} from 'src/app/services/loader.service';
import {
  ModelReactionByTeamIdGroupMemberIdCreatedAtCompositeKeyConditionInput,
  ModelReactionByTeamIdGroupMemberIdCreatedAtCompositeKeyInput,
  ModelReactionFilterInput
} from '../../../API';
import {UserInfoPage} from '../user-info/user-info.page';


@Component({
  selector: 'app-menu-drawer',
  templateUrl: './menu-drawer.component.html',
  styleUrls: ['./menu-drawer.component.scss'],
})
export class MenuDrawerComponent implements OnInit {
  public text: string;
  @Output() textOutput = new EventEmitter<string>();
  userId: string = '';
  items: any = null;
  useritems: any = [];
  groupId: string = '';
  teams: any = [];
  mode: string = 'NG';
  isAdmin: boolean = false;
  directMessagefor: any = [];
  directMessageto: any = [];
  directMessagetodata: number;
  networkStatus = true;
  listener: any;
  loader: any;

  menuIndex: number = 1;
  teamReadFlagTemp = [];
  offViewTeamIds = [];
  lastGetDate = null;
  allMailingReadFlag: boolean = false;
  mailingIconFlag: boolean = false;
  mailingIndex: number = 0;
  mailingLastIndex: number = 0;
  corporate: any = null;

  constructor(
    private modalController: ModalController,
    public router: Router,
    private menu: MenuController,
    private alertController: AlertController,
    private navController: NavController,
    private inAppBrowser: InAppBrowser,
    private authPrv: AuthProvider,
    public loadingController: LoadingController,
    private netService: NetworkService,
    public storageService: StorageService,
    private pushNoticeProvider: PushNoticeProvider,
    private APIPrv: ApiProvider,
    private loaderService: LoaderService
  ) {

  }

  async ngOnInit() {
  }

  isOnline() {
    return !this.networkStatus;
  }

  ionViewWillLeave() {
    this.netService.stopListenNetwork();
  }

  // ハンバーガーメニューを開いた時
  async onMenuOpen() {
    try {
      this.networkStatus = await this.netService.getStatus();
      await this.netService.startListenNetwork();
      this.listener = Network.addListener('networkStatusChange', async (status) => {
        this.networkStatus = status.connected;
      });

      await this.getuser();
    } catch (e) {
      console.log(e);
    }
  }

  //ログインユーザのデータの取得
  async getuser() {
    //userデータを取得
    let id = await this.storageService.getUserId();
    let groupId = await this.storageService.getGroupId();

    //クループメンバーIDを特定
    this.userId = id + '#' + groupId;
    let user = await this.APIPrv.graphqlLamda(Query.getGroupMember, {id: this.userId});

    // 一致するGroupMemberがない場合、Logout処理を行う
    if (!user['data']['getGroupMember']) {
      // menu close
      setTimeout(async () => await this.menu.close(), 500);

      await this.storageService.logout();
      await this.router.navigateByUrl('/error');
      return false;
    }

    //ユーザ情報
    this.items = user['data']['getGroupMember'];
    let getCorporate = await this.APIPrv.graphqlLamda(Query.getCorporate, {id: this.items.group.corporateId});
    this.corporate = getCorporate['data']['getCorporate'];

    // ハンバーガーメニュー初回に開いた場合にデータが反映されないため、データ更新
    this.isAdmin = this.items.role === 1;

    //所属チームを洗い出す
    let team = await this.APIPrv.graphqlLamda(Custom.teamMemberByGroupMemberId, {groupMemberId: this.userId});
    const teams = team['data']['teamMemberByGroupMemberId'].items;
    const defaultTeam = teams.filter(tm => tm.team.isDefault);
    const normalTeams = teams.filter(tm => !tm.team.isDefault);

    //チーム情報
    if (user['data']['getGroupMember']['group']['displayAllMember'] === 0) {
      this.teams = normalTeams;
    } else {
      this.teams = defaultTeam.concat(normalTeams);
    }

    this.offViewTeamIds = [];

    // Tempから既読フラグを読み込む
    this.teams.map(item => {
      // teamViewが0であるTeamIdを取得
      if (item.teamView === 0) {
        this.offViewTeamIds.push(item.team.id);
      }

      if (this.teamReadFlagTemp.includes(item.team.id)) {
        item['readFlag'] = true;
        this.mailingIconFlag = true
        this.allMailingReadFlag = this.checkOffViewTeamFlag();
      } else {
        item['readFlag'] = false;
      }
    })
    this.getReactionList();

    this.mode = 'ok';
    //DirectMessage情報取得
    let DirectMessageto = await this.APIPrv.graphql(Query.directMessageByToGroupMemberId, {toGroupMemberId: this.userId});

    let DirectMessagefor = await this.APIPrv.graphql(Query.directMessageByGroupMemberId, {groupMemberId: this.userId});

    this.directMessageto = DirectMessageto['data']['directMessageByToGroupMemberId'].items;
    this.directMessagefor = DirectMessagefor['data']['directMessageByGroupMemberId'].items;

    //DirectMessageメッセージの既読判定
    let messageCount = 0;
    this.directMessageto.forEach(i => {
      //読了フラグ（相手先）チェック
      if (i['toRead'] === 0) {
        messageCount++;
      }
    });
    this.directMessagefor.forEach(i => {
      //読了フラグ（起票者）チェック
      if (i['read'] === 0) {
        messageCount++;
      }
    });
    this.directMessagetodata = messageCount;
  }

  get directMessageCheck() {
    if (this.directMessagetodata > 0) {
      return true;
    }
    return false;
  }

  link(path, param = null) {
    this.menu.close('left');

    const linkPath = param ? `${path}${param}` : path;
    this.router.navigateByUrl(linkPath);
  }

  link_inAppBrowser(path) {
    this.menu.close('left');
    const browser = this.inAppBrowser.create(path);
    browser.show();
  }

  type = 'got';

  logForm() {
    console.log(this.text);
    this.textOutput.emit(this.text);
  }

  async logoout() {
    this.loaderService.presentLoading();

    await this.menu.close();

    try {
      this.menu.swipeGesture(false, 'left');
      this.menu.swipeGesture(false, 'right');
      this.teams = [];

      this.items = null;
      this.corporate = null;
      this.allMailingReadFlag = false;
      this.mailingIconFlag = false;

      try{
        const userDeviceId = await this.storageService.getUserDeviceId();
        if(userDeviceId){
          const input: DeleteUserDeviceInput = {
            id: userDeviceId
          };
          await this.APIPrv.graphql(Mutation.deleteUserDevice, {input});
        }
      } catch (e) {
        console.log(e);
      }
      await this.storageService.allClear();
      this.pushNoticeProvider.clearRegister();
      await this.authPrv.logout();

    } catch (e) {
      console.log(e);
    }

    this.loaderService.dismiss();
  }

  async logoutAlert() {
    const alert = await this.alertController.create({
      header: 'ログアウトしますか？',
      buttons: [
        {
          text: 'はい',
          handler: async () => {
            await this.logoout();
            this.logoutMessage();
            this.navController.navigateBack('/login-id-input');
          }
        }, {
          text: 'いいえ',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  async logoutMessage() {
    const alert = await this.alertController.create({
      header: 'ログアウトしました。',
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentLoading() {
    this.loader = await this.loadingController.create({
      cssClass: 'my-custom-class',
    });
    this.loader.present();
  }

  changeMenuIndex(index) {
    if (index === 2) {
      // DM画面
      this.link('/direct-message-list');
      return;
    }

    if (index === 3) {
      // 設定画面
      this.link('/account-notification-setting');
      return;
    }

    this.menuIndex = index;
  }

  getReactionList() {
    // 5秒に1回取得できる
    const limitSecond = 10;
    if (this.lastGetDate && this.lastGetDate > new Date()) {
      return;
    }

    this.teamReadFlagTemp = [];
    this.mailingIndex = 0;
    this.mailingLastIndex = this.teams.length;
    this.teams.map(team => {
      const groupMemberInput: ModelReactionByTeamIdGroupMemberIdCreatedAtCompositeKeyConditionInput = {
        beginsWith: { groupMemberId: this.userId } as ModelReactionByTeamIdGroupMemberIdCreatedAtCompositeKeyInput
      };

      const readFilter: ModelReactionFilterInput = {
        read: { eq: 0 }
      }

      this.APIPrv.graphqlLamda(Custom.menuDrawerReaction, {
        teamId: team.team.id,
        groupMemberIdCreatedAt: groupMemberInput,
        filter: readFilter,
        limit: 600,
      }).then(result => {
        this.mailingIndex++;
        if (result['data']['reactionbyTeamIdGroupMemberIdCreatedAt']['items']['length']) {
          team.readFlag = true;
          this.teamReadFlagTemp.push(team.team.id);
        } else {
          team.readFlag = false;
        }

        // 全チームのデータ取得が終わったら、すべての連絡トレイアイコンFlagを更新
        if (this.mailingIndex === this.mailingLastIndex) {
          if (this.teamReadFlagTemp.length > 0) {
            this.mailingIconFlag = true;
            this.allMailingReadFlag = this.checkOffViewTeamFlag();
          } else {
            this.allMailingReadFlag = false;
            this.mailingIconFlag = false;
          }
        }
      })
    });

    let newDate = new Date();
    newDate.setSeconds(newDate.getSeconds() + limitSecond);
    this.lastGetDate = newDate;
  }

  async userInfoModal() {
    const modal = await this.modalController.create({
      component: UserInfoPage,
    });


    modal.onDidDismiss()
      .then(() => {});

    setTimeout(async () => await this.menu.close(), 500);

    return await modal.present();
  }

  checkOffViewTeamFlag() {
    // teamViewが0のチーム以外のReadFlagをチェックする
    const checkedOffViewTeam = this.teamReadFlagTemp.filter(teamId => !this.offViewTeamIds.includes(teamId));
    if (checkedOffViewTeam.length > 0) {
      return true;
    }
    return false;
  }
}
