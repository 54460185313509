import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavParams, LoadingController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ErrorMessage, ErrorMessageInterface } from '../../error-const';
import {ApiProvider} from "../../../providers/api";
import * as Query from '../../../graphql/queries';
import { StorageService } from '../../services/storage.service';
import { CreateTroubleReportInput } from '../../../API';
import * as Mutation from '../../../graphql/mutations';
import { SEND_MAIL_TYPE } from 'src/app/services/email-type';
import { NetworkService } from 'src/app/services/network.service';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-notification-report',
  templateUrl: './notification-report.page.html',
  styleUrls: ['./notification-report.page.scss'],
})
export class NotificationReportPage implements OnInit {
  public inputForm: UntypedFormGroup;
  isSubmitted = false;
  teamid: string = '';
  team: string = '';
  groupmemberId: string = '';
  public errorMessage: ErrorMessageInterface = ErrorMessage;
  networkStatus = true;
  listener: any;
  loader: any;
  submitFlag: boolean = false;
  isError: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    private alertController: AlertController,
    private modalController: ModalController,
    private netService: NetworkService,
    private navParams: NavParams,
    private loadingController: LoadingController,
    public storageService: StorageService,
    private APIPrv: ApiProvider,
  ) {
  }

  async ngOnInit() {
    try {
      this.inputForm = this.formBuilder.group({
        contents: [''],
      });

      const isLogin = await this.storageService.loginCheck();
      if (!isLogin) {
        this.dismiss();
        return;
      }

      this.teamid = this.navParams.data.teamId; //パラメータの受け取り
      this.getTeam();
    } catch (e) {
      console.log(e)
    }
  }

  async ionViewDidEnter() {
    this.networkStatus = await this.netService.getStatus();
    await this.netService.startListenNetwork();
    this.listener = Network.addListener('networkStatusChange', async (status) => {
      this.networkStatus = status.connected;
    });
  }

  ionViewWillLeave() {
    this.netService.stopListenNetwork();
  }

  isOnline() {
    return !this.networkStatus;
  }

  //チーム情報を取得
  async getTeam() {
    //groupmemberIdを特定
    let id = await this.storageService.getUserId();
    let groupId = await this.storageService.getGroupId();
    this.groupmemberId = id + '#' + groupId;

    //チーム情報
    const team = await this.APIPrv.graphqlLamda(Query.getTeam, { id: this.teamid });
    this.team = team['data']['getTeam'];
  }

  dismiss() {
    this.modalController.dismiss();
  }

  get errorControl() {
    return this.inputForm.controls;
  }

  get formRequired() {
    if (!this.inputForm.controls.contents.value || !this.networkStatus) {
      return true;
    }
    return false;
  }

  inputContents() {
    this.isError = false;
  }

  async submitForm() {
    this.submitFlag = true;

    const isLogin = await this.storageService.loginCheck();
    if (!isLogin) {
      this.dismiss();
      return;
    }

    this.isSubmitted = true;
    let valid = false;
    let headers = '';
    this.submitFlag = false;

    this.dataValidtaion();
    if (this.isError === true) {
      return false;
    }

    await this.ADDMessage();
    valid = true;
    headers = '送信しました。';
    await this.sendMessage(valid, headers);
  }

  dataValidtaion() {
    if (this.inputForm.controls.contents.value.length > 500) {
      this.isError = true;
    }
  }

  async sendMessage(valid, headers) {
    const alert = await this.alertController.create({
      header: headers,
      backdropDismiss: false,
      buttons: [{
        text: 'OK',
        handler: () => {
          if (valid) {
            this.dismiss();
          }
        }
      }]
    });

    await alert.present();
  }

  // 入力内容を作成
  async ADDMessage() {
    await this.presentLoading();

    try {
      const currentTimeStamp = new Date().toISOString();

      const input = {
        teamId: this.teamid,
        groupMemberId: this.groupmemberId,
        content: this.inputForm.value.contents
      };

      const result = await this.APIPrv.graphql(Mutation.createTroubleReportApi, input);
      const handleResult = JSON.parse(result['data']['createTroubleReportApi']);

      // メールの送信
      const mailResult = await this.APIPrv.graphql(Mutation.sendEmailApi, {
        type: SEND_MAIL_TYPE.TROUBLE_REPORT,
        id: handleResult.troubleReportId,
      });
      console.log("mailResult", mailResult);

    } catch (e) {
      console.log(e);
    }

    if (this.loader) {
      this.loader.dismiss();
    }
  }

  async presentLoading() {
    this.loader = await this.loadingController.create({
      cssClass: 'my-custom-class',
    });
    this.loader.present();
  }

}
