import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Router} from '@angular/router';
import {NavController, AlertController} from '@ionic/angular';
import {ApiProvider} from '../../providers/api';
import * as Query from 'src/graphql/queries';
import {AuthProvider} from 'src/providers/auth';
import * as Custom from '../../custom/customQueries';

const USER_ID = 'userId';
const CORPORATE_ID = 'corporateId';
const GROUP_ID = 'groupId';
const MAIL = 'email';
const LOGIN_STATUS = 'loginStatus';
const MODE = 'mode';
const ADMIN = 'admin';
const USER_DEVICE_ID = 'userDeviceId';
const DEVICE_TOKEN = 'deviceToken';
const PRE_TIME = 'preTime';
const SIGNIN = 'signIn';
const TOPIC_DRAFT_FLAG = 'topicDraftFlag';
const TOPIC_DRAFT_DATA = 'topicDraftData';
const APP_SCHEME_FLAG = 'appSchemeFlag'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private cognitoUser: any;
  private initPushTime: string;
  private groupMember: any;

  constructor(
    private storage: Storage,
    private authPrv: AuthProvider,
    private alertController: AlertController,
    public router: Router,
    private navController: NavController,
    private APIPrv: ApiProvider,
  ) {
  }

  async allClear() {
    await this.storage.clear()
    this.cognitoUser = null;
    this.initPushTime = null;
  }

  async removeUserData() {
    await this.setSignIn(false);
    await this.storage.remove(USER_ID);
    await this.storage.remove(CORPORATE_ID);
    await this.storage.remove(GROUP_ID);
    await this.storage.remove(MAIL);
    await this.storage.remove(LOGIN_STATUS);
    await this.storage.remove(ADMIN);
    await this.storage.remove(TOPIC_DRAFT_FLAG);
    await this.storage.remove(TOPIC_DRAFT_DATA);
  }

  async setUserId(userId: String) {
    return await this.storage.set(USER_ID, userId);
  }

  async getUserId() {
    const userId = await this.storage.get(USER_ID);
    await this.checkValue(userId);
    return userId;
  }

  async removeUserId() {
    return await this.storage.remove(USER_ID);
  }

  async setCorporateId(corporateId: String) {
    return await this.storage.set(CORPORATE_ID, corporateId);
  }

  async getCorporateId() {
    const corporateId = await this.storage.get(CORPORATE_ID);
    await this.checkValue(corporateId);
    return corporateId;
  }

  async removeCorporateId() {
    return await this.storage.remove(CORPORATE_ID);
  }

  async setGroupId(groupId: String) {
    return await this.storage.set(GROUP_ID, groupId);
  }

  async getGroupId() {
    const groupId = await this.storage.get(GROUP_ID);
    await this.checkValue(groupId);
    return groupId;
  }

  async removeGroupId() {
    return await this.storage.remove(GROUP_ID);
  }

  async setEmail(email: String) {
    return await this.storage.set(MAIL, email);
  }

  async getEmail() {
    const email = await this.storage.get(MAIL);
    await this.checkValue(email);
    return email;
  }

  async removeEmail() {
    return await this.storage.remove(MAIL);
  }

  async setLoginStatus(status: boolean) {
    this.setSignIn(status);
    return await this.storage.set(LOGIN_STATUS, status);
  }

  async getLoginStatus() {
    return await this.storage.get(LOGIN_STATUS);
  }

  async removeLoginStatus() {
    return await this.storage.remove(LOGIN_STATUS);
  }

  async setSignIn(status: boolean) {
    return await this.storage.set(SIGNIN, status);
  }

  async getSignIn() {
    return await this.storage.get(SIGNIN);
  }

  async removeSignIn() {
    return await this.storage.remove(SIGNIN);
  }

  async loginCheck() {
    const getLoginStatus = await this.getLoginStatus();
    if (!getLoginStatus) {
      const signIn = await this.getSignIn();
      if (!signIn) {
        await this.navController.navigateRoot('/error');
      }
      return false;
    }
    await this.checkGroupMemberRole();

    const checkGroup = await this.checkGroupStatus();
    if (!checkGroup) {
      await this.logout();
      await this.navController.navigateRoot('/error');
      return false;
    }

    return true;
  }

  async logout() {
    await this.authPrv.logout();
    await this.removeUserData();
  }

  async checkGroupStatus() {
    try {
      const group = this.groupMember.group;
      return !(!group || group.status === 99);
    } catch(e) {
        console.log(e)
    }
    return false;
  }

  async checkGroupMemberRole() {
    const userId = await this.getUserId();
    const groupId = await this.getGroupId();
    const groupMemberId = userId + '#' + groupId;

    try {
      const getGroupMember = await this.APIPrv.graphqlLamda(Custom.getGroupMemberForLoginCheck, { id: groupMemberId });
      this.groupMember = getGroupMember['data']['getGroupMember'];
    } catch (e) {
      console.log(e);

      // 権限エラーの場合（ユーザーが削除された場合）ログアウト
      await this.logout();
      await this.navController.navigateRoot('/error');
      return false;
    }

    if (!this.groupMember) {
      await this.logout();
      await this.navController.navigateRoot('/error');
      return false;
    }

    if (this.groupMember.role === 1) {
      await this.setAdmin(true);
    } else {
      await this.setAdmin(false);
    }
  }

  async setMode(mode: String) {
    return await this.storage.set(MODE, mode);
  }

  async getMode() {
    return await this.storage.get(MODE);
  }

  async removeMode() {
    return await this.storage.remove(MODE);
  }

  async setAdmin(admin: boolean) {
    return await this.storage.set(ADMIN, admin);
  }

  async getAdmin() {
    return await this.storage.get(ADMIN);
  }

  async removeAdmin() {
    return await this.storage.remove(ADMIN);
  }


  async setUserDeviceId(userDeviceId: String) {
    return await this.storage.set(USER_DEVICE_ID, userDeviceId);
  }

  async getUserDeviceId() {
    return await this.storage.get(USER_DEVICE_ID);
  }

  async removeUserDeviceId() {
    return await this.storage.remove(USER_DEVICE_ID);
  }

  async setDeviceToken(deviceToken: String) {
    return await this.storage.set(DEVICE_TOKEN, deviceToken);
  }

  async getDeviceToken() {
    return await this.storage.get(DEVICE_TOKEN);
  }

  async removeDeviceToken() {
    return await this.storage.remove(DEVICE_TOKEN);
  }

  async setInitPushTime(lastPushTime) {
    this.initPushTime = lastPushTime;
  }

  async getInitPushTime() {
    return this.initPushTime;
  }

  async setPreTime(time: number) {
    return await this.storage.set(PRE_TIME, time);
  }

  async getPreTime() {
    let time = await this.storage.get(PRE_TIME);
    return time as number;
  }

  async removePreTime() {
    return await this.storage.remove(PRE_TIME);
  }

  async setCognitoUser(cognitoUser) {
    this.cognitoUser = cognitoUser;
  }

  async getCognitoUser() {
    return this.cognitoUser;
  }

  async checkValue(value: string) {
    // 習得したデータがNullまたは空白の場合、Storageのデータを削除し、ログインページに遷移
    if (!value) {
      this.removeUserData();
      // エラーページの際はログイン画面に自動遷移させない
      if (location.pathname === '/error') {
        return;
      }
      const signIn = await this.getSignIn();
      if (!signIn) {
        await this.navController.navigateRoot('/error');
      }
    }
  }

  async checkLoginGroupMember(id) {
    const userId = await this.getUserId();
    const groupId = await this.getGroupId();
    const groupMemberId = userId + '#' + groupId;
    return groupMemberId === id;
  }

  async setTopicDraftData(topic: any) {
    return await this.storage.set(TOPIC_DRAFT_DATA, topic);
  }

  async getTopicDraftData() {
    return await this.storage.get(TOPIC_DRAFT_DATA);
  }

  async removeTopicDraftData() {
    return await this.storage.remove(TOPIC_DRAFT_DATA);
  }

  async setTopicDraftFlag(topic: boolean) {
    return await this.storage.set(TOPIC_DRAFT_FLAG, topic);
  }

  async getTopicDraftFlag() {
    return await this.storage.get(TOPIC_DRAFT_FLAG);
  }

  async removeTopicDraftFlag() {
    return await this.storage.remove(TOPIC_DRAFT_FLAG);
  }

  async getAppSchemeFlag() {
    return await this.storage.get(APP_SCHEME_FLAG);
  }

  async setAppSchemeFlag(flag: boolean) {
    return await this.storage.set(APP_SCHEME_FLAG, flag);
  }

}
