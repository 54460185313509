import { Injectable } from '@angular/core';
import API, {graphqlOperation} from "@aws-amplify/api";
import {AuthProvider} from 'src/providers/auth';

@Injectable({
  providedIn: 'root'
})
export class ApiProvider {

  constructor(
    public authPrv: AuthProvider,
  ) { }


  async graphql(query, params): Promise<Object> {
    // ローカルストレージ　から取得
    const corporateId = 'front';
    const wafHeader = {'X-CorporateId':　corporateId};

    return API.graphql(graphqlOperation(query, params), wafHeader);
  }

  async graphqlLamda(query, params): Promise<Object> {
    // カスタムリクエストヘッダを付与
    const corporateId = 'front';

    let lambdaAuthToken = await this.authPrv.getAccessToken();
    lambdaAuthToken.corporateId = corporateId;
    const wafHeader = {'X-CorporateId':　corporateId};
    //console.log(JSON.stringify(lambdaAuthToken))
    return  API.graphql(
      {
        query: query,
        variables:  params,
        authMode: 'AWS_LAMBDA',
        authToken: JSON.stringify(lambdaAuthToken)
      },
      wafHeader
    );
  }


}
