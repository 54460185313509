/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const CorporateFragment = /* GraphQL */`
  fragment CorporateModel on Corporate {
    id
    name
    groupCount
    email
    serviceId
  }
`;

export const GroupFragment = /* GraphQL */`
  fragment GroupModel on Group {
    id
    name
    teamCount
    memberCount
  }
`;

export const getCustomCorporate = /* GraphQL */ `
  query GetCorporate($id: ID!) {
    getCorporate(id: $id) {
      ...CorporateModel
      groups (sortDirection: DESC, limit: 1000) {
        items {
          ...GroupModel
        }
        nextToken
      }
      corporateAdmins(sortDirection: DESC, limit: 1000)  {
        items {
          id
          name
        }
        nextToken
      }
    }
  }
  ${CorporateFragment}
  ${GroupFragment}
`;

export const teamMemberByGroupMemberId = /* GraphQL */ `
  query TeamMemberByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
        team{
          id
          name
          isDefault
          memberCount
          createdAt
        }
      }
      nextToken
    }
  }
`;

export const topicReplyByTopicId = /* GraphQL */ `
  query TopicReplyByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicReplyByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        groupMemberId
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const reactionbyGroupMemberIdCreatedAt = /* GraphQL */ `
  query ReactionbyGroupMemberIdCreatedAt(
    $groupMemberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionbyGroupMemberIdCreatedAt(
      groupMemberId: $groupMemberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
        topic {
          id
          groupMemberId
          groupMember {
            name
          }
          topicReplies (limit: 1) {
            items {
              id
            }
          }
          teamId
          title
          content
          attached
          isQuestion
          questionDeadline
          createdAt
          updatedAt
        }
        team {
          id
          groupId
          name
          teamMembers (limit: 1000) {
            items {
              id
              teamView
              groupMemberId
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const reactionbyTeamIdGroupMemberIdCreatedAt = /* GraphQL */ `
  query ReactionbyTeamIdGroupMemberIdCreatedAt(
    $teamId: ID!
    $groupMemberIdCreatedAt: ModelReactionByTeamIdGroupMemberIdCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionbyTeamIdGroupMemberIdCreatedAt(
      teamId: $teamId
      groupMemberIdCreatedAt: $groupMemberIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
        topic {
          id
          groupMemberId
          groupMember {
            name
          }
          topicReplies (limit: 1) {
            items {
              id
            }
          }
          teamId
          title
          content
          isQuestion
          questionDeadline
          attached
          createdAt
          updatedAt
        }
        team {
          id
          groupId
          name
          teamMembers (limit: 1000) {
            items {
              id
              teamView
              groupMemberId
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const mailingGetGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      userId
      groupId
      name
      email
      role
      memberCode
      joinedTeamCount
      joinedTeams {
        teamId
        teamName
      }
      emailNotify
      pushNotify
      termsAgreedAt
      serviceId
      createdAt
      updatedAt
    }
  }
`;

export const topicByTeamId = /* GraphQL */ `
  query TopicByTeamId(
    $teamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
        groupMember {
          name
        }
      }
      nextToken
    }
  }
`;

export const directMessageByToGroupMemberId = /* GraphQL */ `
  query DirectMessageByToGroupMemberId(
    $toGroupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageByToGroupMemberId(
      toGroupMemberId: $toGroupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupMemberId
        groupMember {
          id
          userId
          groupId
          name
          email
          role
        }
        toGroupMemberId
        toRead
        toName
        timestamp
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const directMessageByGroupMemberId = /* GraphQL */ `
  query DirectMessageByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupMemberId
        groupMember {
          id
          userId
          groupId
          name
          email
          role
        }
        toGroupMemberId
        toName
        timestamp
        read
        toRead
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const reactionByTopicId = /* GraphQL */ `
  query ReactionByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        groupMemberId
        groupMember {
          id
          name
        }
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const reactionTopicReply = /* GraphQL */ `
  query ReactionByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        topicId
        topic {
          topicReplies(limit: 1000) {
            items {
              groupMemberId
              groupMember {
                id
                name
              }
              content
              createdAt
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const reactionByGroupMemberId = /* GraphQL */ `
  query ReactionByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        topic {
          groupMemberId
          groupMember {
            name
          }
          title
          content
          isQuestion
          questionDetails {
            items {
              questionNo
              content
              answerCount
              questionAnswers {
                items {
                  groupMemberId
                  questionDetailId
                }
              }
            }
          }
          answerMembersCount
          destinationMembersCount
          alreadyReadMembersCount
          attached
          attachedFiles {
            id
            contentType
            fileName
            fileSize
            downloadUrl
            downloadThumbnailUrl
            uploadUrl
          }
          createdAt
          updatedAt
          team {
            id
            groupId
            isDefault
            name
            memberCount
            serviceId
            createdAt
            updatedAt
          }
          topicReplies {
            items {
              groupMemberId
              content
              createdAt
            }
            nextToken
          }
        }
        groupMemberId
        groupMember {
          name
        }
        teamId
        team {
          name
        }
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      teamId
      groupMemberId
      groupMember {
        id
        name
      }
      title
      content
      isQuestion
      answerMembersCount
      destinationMembersCount
      alreadyReadMembersCount
      attached
      attachedFiles {
        id
        contentType
        fileName
        fileSize
        downloadUrl
        downloadThumbnailUrl
        uploadUrl
      }
      createdAt
      updatedAt
      team {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      topicReplies {
        nextToken
      }
      reactions(limit: 1000) {
        items {
          id
          owner
          topicId
          groupMemberId
          teamId
          dispOnHome
          read
          answered
          answeredSorter
          answeredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getTopicDetail = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      groupMemberId
      groupMember {
        id
        name
      }
      title
      content
      isQuestion
      questionDetails(limit: 10) {
        items {
          id
          questionNo
          content
          answerCount
          questionAnswers(limit: 1000) {
            items {
              id
              groupMemberId
              questionDetailId
              groupMember {
                name
                role
                userId
              }
            }
          }
        }
      }
      answerMembersCount
      questionDeadline
      destinationMembersCount
      alreadyReadMembersCount
      attached
      attachedFiles {
        id
        contentType
        fileName
        fileSize
        downloadUrl
        downloadThumbnailUrl
        uploadUrl
      }
      createdAt
      updatedAt
      team {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      topicReplies(limit: 1000) {
        items {
          groupMemberId
          groupMember {
            id
            name
          }
          content
          createdAt
        }
      }
    }
  }
`;

export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      teamId
      groupMemberId
      title
      content
      isQuestion
      answerMembersCount
      destinationMembersCount
      alreadyReadMembersCount
      attached
      attachedFiles {
        id
        contentType
        fileName
        fileSize
        downloadUrl
        downloadThumbnailUrl
        uploadUrl
      }
      createdAt
      updatedAt
      topicReplies {
        nextToken
      }
      reactions {
        nextToken
      }
    }
  }
`;

export const getGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      userId
      groupId
      name
      email
      role
      memberCode
      joinedTeamCount
      joinedTeams {
        teamId
        teamName
      }
      badge {
        memberId
        topicUnread
        topicUnreadCount
        topicDmUnread
        topicDmUnreadCount
        topicAnnounceUnread
        topicAnnounceUnreadCount
        topicUnanswered
        topicUnansweredCount
        adminGroupHasPreMembers
        adminGroupHasPreMembersCount
      }
      emailNotify
      pushNotify
      termsAgreedAt
      serviceId
      createdAt
      updatedAt
      reactions {
        nextToken
      }
      topics {
        nextToken
      }
      teamMembers {
        nextToken
      }
    }
  }
`;

export const getGroupMemberForLoginCheck = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      userId
      groupId
      group {
        id
        name
        status
      }
      name
      email
      role
      memberCode
    }
  }
`;

export const getGroupMemberDetail = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      userId
      groupId
      group {
        id
        name
        corporateId
      }
      name
      email
      role
      memberCode
      joinedTeamCount
      joinedTeams {
        teamId
        teamName
      }
      badge {
        memberId
        topicUnread
        topicUnreadCount
        topicDmUnread
        topicDmUnreadCount
        topicAnnounceUnread
        topicAnnounceUnreadCount
        topicUnanswered
        topicUnansweredCount
        adminGroupHasPreMembers
        adminGroupHasPreMembersCount
      }
      emailNotify
      pushNotify
      termsAgreedAt
      serviceId
      createdAt
      updatedAt
      reactions {
        nextToken
      }
      topics {
        nextToken
      }
      teamMembers(limit: 1000) {
        items {
          id
          teamId
          team {
            id
            name
            isDefault
          }
        }
      }
    }
  }
`;

export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      userId
      groupId
      name
      email
      role
      memberCode
      joinedTeamCount
      joinedTeams {
        teamId
        teamName
      }
      badge {
        memberId
        topicUnread
        topicUnreadCount
        topicDmUnread
        topicDmUnreadCount
        topicAnnounceUnread
        topicAnnounceUnreadCount
        topicUnanswered
        topicUnansweredCount
        adminGroupHasPreMembers
        adminGroupHasPreMembersCount
      }
      emailNotify
      pushNotify
      termsAgreedAt
      serviceId
      createdAt
      updatedAt
      reactions {
        nextToken
      }
      topics {
        nextToken
      }
      teamMembers {
        nextToken
      }
    }
  }
`;
export const updateReaction = /* GraphQL */ `
  mutation UpdateReaction(
    $input: UpdateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    updateReaction(input: $input, condition: $condition) {
      id
      owner
      topicId
      groupMemberId
      teamId
      dispOnHome
      read
      answered
      answeredSorter
      answeredAt
      createdAt
      updatedAt
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      topic {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        attachedFiles {
          id
          contentType
          fileName
          fileSize
          downloadUrl
          downloadThumbnailUrl
          uploadUrl
        }
        createdAt
        updatedAt
        groupMember {
          name
        }
        team {
          id
          name
        }
        questionDetails {
          items {
            id
            topicId
            questionNo
            content
            answerCount
            topicCreator
            createdAt
            updatedAt
            questionAnswers {
              items {
                id
                groupMemberId
                questionDetailId
                groupMember {
                  name
                  role
                  userId
                }
              }
            }
          }
        }
        topicReplies(limit: 1000) {
          items {
            groupMemberId
            groupMember {
              id
              name
            }
            content
            createdAt
          }
        }
      }
    }
  }
`;

export const getDirectMessage = /* GraphQL */ `
  query GetDirectMessage($id: ID!) {
    getDirectMessage(id: $id) {
      id
      groupMemberId
      toGroupMemberId
      toName
      timestamp
      read
      toRead
      owners
      createdAt
      updatedAt
    }
  }
`;


export const updateDirectMessage = /* GraphQL */ `
  mutation UpdateDirectMessage(
    $input: UpdateDirectMessageInput!
    $condition: ModelDirectMessageConditionInput
  ) {
    updateDirectMessage(input: $input, condition: $condition) {
      id
      groupMemberId
      toGroupMemberId
      toName
      timestamp
      read
      toRead
      owners
      createdAt
      updatedAt
      directMessageComments {
        nextToken
      }
    }
  }
`;

export const deleteQuestionAnswer = /* GraphQL */ `
  mutation DeleteQuestionAnswer(
    $input: DeleteQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    deleteQuestionAnswer(input: $input, condition: $condition) {
      id
      owner
      questionDetailId
      topicId
      groupMemberId
      createdAt
      updatedAt
    }
  }
`;

export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      userId
      groupMemberId
      teamId
      name
      email
      memberCode
      teamNotify
      teamView
      createdAt
      updatedAt
    }
  }
`;

export const groupMemberByGroupIdForAddDirectMessage = /* GraphQL */ `
  query GroupMemberByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        role
      }
      nextToken
    }
  }
`;

export const teamMemberByTeamIdCreated = /* GraphQL */ `
  query TeamMemberByTeamIdCreated(
    $teamId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamIdCreated(
      teamId: $teamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        groupMember {
          id
          role
        }
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTeamAndTeamMember = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      groupId
      isDefault
      name
      memberCount
      serviceId
      createdAt
      updatedAt
      teamMembers (limit: 1000) {
        items {
          userId
          name
          groupMemberId
          groupMember {
            role
          }
          createdAt
        }
        nextToken
      }
    }
  }
`;

export const menuDrawerReaction = /* GraphQL */ `
  query ReactionbyTeamIdGroupMemberIdCreatedAt(
    $teamId: ID!
    $groupMemberIdCreatedAt: ModelReactionByTeamIdGroupMemberIdCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionbyTeamIdGroupMemberIdCreatedAt(
      teamId: $teamId
      groupMemberIdCreatedAt: $groupMemberIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        read
      }
      nextToken
    }
  }
`;