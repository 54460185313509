/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPresignedUrl = /* GraphQL */ `
  query GetPresignedUrl(
    $s3Operation: String
    $objectKey: String
    $contentType: String
  ) {
    getPresignedUrl(
      s3Operation: $s3Operation
      objectKey: $objectKey
      contentType: $contentType
    )
  }
`;
export const userDevicesebyUserId = /* GraphQL */ `
  query UserDevicesebyUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDevicesebyUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kind
        userId
        deviceToken
        endpointArn
        expiredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      termsAgreedAt
      lastUpdatedAt
      createdAt
      updatedAt
      groupMembers {
        nextToken
      }
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        termsAgreedAt
        lastUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getServiceAdmin = /* GraphQL */ `
  query GetServiceAdmin($id: ID!) {
    getServiceAdmin(id: $id) {
      id
      name
      email
      serviceId
      isProtected
      createdAt
      updatedAt
    }
  }
`;
export const serviceAdminByEmail = /* GraphQL */ `
  query ServiceAdminByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelServiceAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceAdminByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        serviceId
        isProtected
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const serviceAdminByServiceId = /* GraphQL */ `
  query ServiceAdminByServiceId(
    $serviceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelServiceAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceAdminByServiceId(
      serviceId: $serviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        serviceId
        isProtected
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const serviceAdminByServiceIdCreated = /* GraphQL */ `
  query ServiceAdminByServiceIdCreated(
    $serviceId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceAdminByServiceIdCreated(
      serviceId: $serviceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        serviceId
        isProtected
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCorporate = /* GraphQL */ `
  query GetCorporate($id: ID!) {
    getCorporate(id: $id) {
      id
      name
      groupCount
      email
      isAlive
      serviceId
      passcode
      passcodeUpdated
      createdAt
      updatedAt
      groups {
        nextToken
      }
      corporateAdmins {
        nextToken
      }
    }
  }
`;
export const corporateByIdName = /* GraphQL */ `
  query CorporateByIdName(
    $id: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateByIdName(
      id: $id
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporateByName = /* GraphQL */ `
  query CorporateByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporatebyisAlive = /* GraphQL */ `
  query CorporatebyisAlive(
    $isAlive: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporatebyisAlive(
      isAlive: $isAlive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporateByServiceId = /* GraphQL */ `
  query CorporateByServiceId(
    $serviceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateByServiceId(
      serviceId: $serviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporateByServiceIdCreated = /* GraphQL */ `
  query CorporateByServiceIdCreated(
    $serviceId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateByServiceIdCreated(
      serviceId: $serviceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporatebyserviceIdId = /* GraphQL */ `
  query CorporatebyserviceIdId(
    $serviceId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporatebyserviceIdId(
      serviceId: $serviceId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporatebyserviceIdName = /* GraphQL */ `
  query CorporatebyserviceIdName(
    $serviceId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporatebyserviceIdName(
      serviceId: $serviceId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCorporateAdmin = /* GraphQL */ `
  query GetCorporateAdmin($id: ID!) {
    getCorporateAdmin(id: $id) {
      id
      corporateId
      name
      email
      phoneNumber
      logoutFlag
      createdAt
      updatedAt
      corporate {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
    }
  }
`;
export const corporateAdminbyCorporateId = /* GraphQL */ `
  query CorporateAdminbyCorporateId(
    $corporateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateAdminbyCorporateId(
      corporateId: $corporateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        email
        phoneNumber
        logoutFlag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporateAdminbyCorporateCreated = /* GraphQL */ `
  query CorporateAdminbyCorporateCreated(
    $corporateId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateAdminbyCorporateCreated(
      corporateId: $corporateId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        email
        phoneNumber
        logoutFlag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporateAdminbyCorporateIdEmail = /* GraphQL */ `
  query CorporateAdminbyCorporateIdEmail(
    $corporateId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateAdminbyCorporateIdEmail(
      corporateId: $corporateId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        email
        phoneNumber
        logoutFlag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const corporateAdminByEmail = /* GraphQL */ `
  query CorporateAdminByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCorporateAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    corporateAdminByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        email
        phoneNumber
        logoutFlag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      corporateId
      name
      usePasscode
      passcode
      teamCount
      memberCount
      memberPauseCount
      status
      serviceId
      displayAllMember
      topicRole
      createdAt
      updatedAt
      groupMembers {
        nextToken
      }
      teams {
        nextToken
      }
    }
  }
`;
export const groupByCorporateId = /* GraphQL */ `
  query GroupByCorporateId(
    $corporateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByCorporateId(
      corporateId: $corporateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupByCorporateIdCreated = /* GraphQL */ `
  query GroupByCorporateIdCreated(
    $corporateId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByCorporateIdCreated(
      corporateId: $corporateId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupByCorporateIdName = /* GraphQL */ `
  query GroupByCorporateIdName(
    $corporateId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByCorporateIdName(
      corporateId: $corporateId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupByCorporateIdId = /* GraphQL */ `
  query GroupByCorporateIdId(
    $corporateId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByCorporateIdId(
      corporateId: $corporateId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupByServiceId = /* GraphQL */ `
  query GroupByServiceId(
    $serviceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByServiceId(
      serviceId: $serviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      userId
      groupId
      name
      email
      role
      memberCode
      joinedTeamCount
      joinedTeams {
        teamId
        teamName
      }
      badge {
        memberId
        topicUnread
        topicUnreadCount
        topicDmUnread
        topicDmUnreadCount
        topicAnnounceUnread
        topicAnnounceUnreadCount
        topicUnanswered
        topicUnansweredCount
        adminGroupHasPreMembers
        adminGroupHasPreMembersCount
      }
      emailNotify
      pushNotify
      termsAgreedAt
      serviceId
      createdAt
      updatedAt
      user {
        id
        email
        termsAgreedAt
        lastUpdatedAt
        createdAt
        updatedAt
      }
      group {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
      reactions {
        nextToken
      }
      topics {
        nextToken
      }
      topicReplies {
        nextToken
      }
      teamMembers {
        nextToken
      }
      directMessages {
        nextToken
      }
    }
  }
`;
export const groupMemberByGroupId = /* GraphQL */ `
  query GroupMemberByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByGroupIdCreated = /* GraphQL */ `
  query GroupMemberByGroupIdCreated(
    $groupId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByGroupIdCreated(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByGroupIdName = /* GraphQL */ `
  query GroupMemberByGroupIdName(
    $groupId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByGroupIdName(
      groupId: $groupId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByGroupIdEmail = /* GraphQL */ `
  query GroupMemberByGroupIdEmail(
    $groupId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByGroupIdEmail(
      groupId: $groupId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByGroupIdMemberCode = /* GraphQL */ `
  query GroupMemberByGroupIdMemberCode(
    $groupId: ID!
    $memberCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByGroupIdMemberCode(
      groupId: $groupId
      memberCode: $memberCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByGroupIdId = /* GraphQL */ `
  query GroupMemberByGroupIdId(
    $groupId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByGroupIdId(
      groupId: $groupId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByName = /* GraphQL */ `
  query GroupMemberByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByEmail = /* GraphQL */ `
  query GroupMemberByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByMemberCode = /* GraphQL */ `
  query GroupMemberByMemberCode(
    $memberCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByMemberCode(
      memberCode: $memberCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMemberByServiceId = /* GraphQL */ `
  query GroupMemberByServiceId(
    $serviceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMemberByServiceId(
      serviceId: $serviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      groupId
      isDefault
      name
      memberCount
      serviceId
      createdAt
      updatedAt
      teamMembers {
        nextToken
      }
      topics {
        nextToken
      }
      reactions {
        nextToken
      }
      group {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
    }
  }
`;
export const teamByGroupId = /* GraphQL */ `
  query TeamByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByGroupIdCreated = /* GraphQL */ `
  query TeamByGroupIdCreated(
    $groupId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByGroupIdCreated(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByGroupIdsDefault = /* GraphQL */ `
  query TeamByGroupIdsDefault(
    $groupId: ID!
    $isDefault: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByGroupIdsDefault(
      groupId: $groupId
      isDefault: $isDefault
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByIsDefault = /* GraphQL */ `
  query TeamByIsDefault(
    $isDefault: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByIsDefault(
      isDefault: $isDefault
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByServiceId = /* GraphQL */ `
  query TeamByServiceId(
    $serviceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByServiceId(
      serviceId: $serviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByGroupMemberId = /* GraphQL */ `
  query TeamMemberByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByGroupMemberIdCreatedAt = /* GraphQL */ `
  query TeamMemberByGroupMemberIdCreatedAt(
    $groupMemberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByGroupMemberIdCreatedAt(
      groupMemberId: $groupMemberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByGroupMemberIdTeamId = /* GraphQL */ `
  query TeamMemberByGroupMemberIdTeamId(
    $groupMemberId: ID!
    $teamId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByGroupMemberIdTeamId(
      groupMemberId: $groupMemberId
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByGroupMemberIdUserId = /* GraphQL */ `
  query TeamMemberByGroupMemberIdUserId(
    $groupMemberId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByGroupMemberIdUserId(
      groupMemberId: $groupMemberId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByTeamId = /* GraphQL */ `
  query TeamMemberByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByTeamIdCreated = /* GraphQL */ `
  query TeamMemberByTeamIdCreated(
    $teamId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamIdCreated(
      teamId: $teamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByTeamIdName = /* GraphQL */ `
  query TeamMemberByTeamIdName(
    $teamId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamIdName(
      teamId: $teamId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByTeamIdEmail = /* GraphQL */ `
  query TeamMemberByTeamIdEmail(
    $teamId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamIdEmail(
      teamId: $teamId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByTeamIdMemberCode = /* GraphQL */ `
  query TeamMemberByTeamIdMemberCode(
    $teamId: ID!
    $memberCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamIdMemberCode(
      teamId: $teamId
      memberCode: $memberCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByTeamIdId = /* GraphQL */ `
  query TeamMemberByTeamIdId(
    $teamId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamIdId(
      teamId: $teamId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberByTeamIdGroupMemberId = /* GraphQL */ `
  query TeamMemberByTeamIdGroupMemberId(
    $teamId: ID!
    $groupMemberId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByTeamIdGroupMemberId(
      teamId: $teamId
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupMemberId
        teamId
        name
        email
        memberCode
        teamNotify
        teamView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      creator
      teamId
      groupMemberId
      title
      content
      isQuestion
      answerMembersCount
      questionDeadline
      destinationMembersCount
      alreadyReadMembersCount
      attached
      attachedFiles {
        id
        contentType
        fileName
        fileSize
        downloadUrl
        downloadThumbnailUrl
        uploadUrl
      }
      createdAt
      updatedAt
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      team {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      topicReplies {
        nextToken
      }
      reactions {
        nextToken
      }
      questionDetails {
        nextToken
      }
    }
  }
`;
export const topicByTeamId = /* GraphQL */ `
  query TopicByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicBybyTeamIdCreated = /* GraphQL */ `
  query TopicBybyTeamIdCreated(
    $teamId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicBybyTeamIdCreated(
      teamId: $teamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicByGroupMemberId = /* GraphQL */ `
  query TopicByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicByGroupMemberIdCreated = /* GraphQL */ `
  query TopicByGroupMemberIdCreated(
    $groupMemberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicByGroupMemberIdCreated(
      groupMemberId: $groupMemberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicReplyByTopicId = /* GraphQL */ `
  query TopicReplyByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicReplyByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        topicId
        groupMemberId
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicReplyByGroupMemberId = /* GraphQL */ `
  query TopicReplyByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicReplyByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        topicId
        groupMemberId
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReaction = /* GraphQL */ `
  query GetReaction($id: ID!) {
    getReaction(id: $id) {
      id
      owner
      topicId
      groupMemberId
      teamId
      dispOnHome
      read
      answered
      answeredSorter
      answeredAt
      createdAt
      updatedAt
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      topic {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      team {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const reactionByTopicId = /* GraphQL */ `
  query ReactionByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionByTopicIdOwner = /* GraphQL */ `
  query ReactionByTopicIdOwner(
    $topicId: ID!
    $owner: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByTopicIdOwner(
      topicId: $topicId
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionByGroupMemberId = /* GraphQL */ `
  query ReactionByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionbyGroupMemberIdCreatedAt = /* GraphQL */ `
  query ReactionbyGroupMemberIdCreatedAt(
    $groupMemberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionbyGroupMemberIdCreatedAt(
      groupMemberId: $groupMemberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionByGroupMemberIdOwner = /* GraphQL */ `
  query ReactionByGroupMemberIdOwner(
    $groupMemberId: ID!
    $owner: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByGroupMemberIdOwner(
      groupMemberId: $groupMemberId
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionByTeamId = /* GraphQL */ `
  query ReactionByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionbyTeamIdGroupMemberId = /* GraphQL */ `
  query ReactionbyTeamIdGroupMemberId(
    $teamId: ID!
    $groupMemberId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionbyTeamIdGroupMemberId(
      teamId: $teamId
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionbyTeamIdGroupMemberIdCreatedAt = /* GraphQL */ `
  query ReactionbyTeamIdGroupMemberIdCreatedAt(
    $teamId: ID!
    $groupMemberIdCreatedAt: ModelReactionByTeamIdGroupMemberIdCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionbyTeamIdGroupMemberIdCreatedAt(
      teamId: $teamId
      groupMemberIdCreatedAt: $groupMemberIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topicId
        groupMemberId
        teamId
        dispOnHome
        read
        answered
        answeredSorter
        answeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionDetail = /* GraphQL */ `
  query GetQuestionDetail($id: ID!) {
    getQuestionDetail(id: $id) {
      id
      creator
      topicId
      questionNo
      content
      answerCount
      topicCreator
      createdAt
      updatedAt
      topic {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      questionAnswers {
        nextToken
      }
    }
  }
`;
export const questionDetailByTopicId = /* GraphQL */ `
  query QuestionDetailByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionDetailByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        topicId
        questionNo
        content
        answerCount
        topicCreator
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionAnswerByTopicId = /* GraphQL */ `
  query QuestionAnswerByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionAnswerByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        questionDetailId
        topicId
        groupMemberId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionAnswerByTopicIdGroupMemberId = /* GraphQL */ `
  query QuestionAnswerByTopicIdGroupMemberId(
    $topicId: ID!
    $groupMemberId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionAnswerByTopicIdGroupMemberId(
      topicId: $topicId
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        questionDetailId
        topicId
        groupMemberId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDirectMessage = /* GraphQL */ `
  query GetDirectMessage($id: ID!) {
    getDirectMessage(id: $id) {
      id
      groupMemberId
      toGroupMemberId
      toName
      timestamp
      read
      toRead
      owners
      createdAt
      updatedAt
      directMessageComments {
        nextToken
      }
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      toGroupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const directMessageByGroupMemberId = /* GraphQL */ `
  query DirectMessageByGroupMemberId(
    $groupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageByGroupMemberId(
      groupMemberId: $groupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupMemberId
        toGroupMemberId
        toName
        timestamp
        read
        toRead
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const directMessageCommentbyGroupMemberIdCreatedAt = /* GraphQL */ `
  query DirectMessageCommentbyGroupMemberIdCreatedAt(
    $groupMemberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageCommentbyGroupMemberIdCreatedAt(
      groupMemberId: $groupMemberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupMemberId
        toGroupMemberId
        toName
        timestamp
        read
        toRead
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const directMessageByToGroupMemberId = /* GraphQL */ `
  query DirectMessageByToGroupMemberId(
    $toGroupMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageByToGroupMemberId(
      toGroupMemberId: $toGroupMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupMemberId
        toGroupMemberId
        toName
        timestamp
        read
        toRead
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const directMessageCommentbyToGroupMemberIdCreatedAt = /* GraphQL */ `
  query DirectMessageCommentbyToGroupMemberIdCreatedAt(
    $toGroupMemberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageCommentbyToGroupMemberIdCreatedAt(
      toGroupMemberId: $toGroupMemberId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupMemberId
        toGroupMemberId
        toName
        timestamp
        read
        toRead
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const directMessageCommentByDirectMessageId = /* GraphQL */ `
  query DirectMessageCommentByDirectMessageId(
    $directMessageId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageCommentByDirectMessageId(
      directMessageId: $directMessageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        directMessageId
        groupMemberId
        content
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const directMessageCommentbyDirectMessageIdCreatedAt = /* GraphQL */ `
  query DirectMessageCommentbyDirectMessageIdCreatedAt(
    $directMessageId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDirectMessageCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    directMessageCommentbyDirectMessageIdCreatedAt(
      directMessageId: $directMessageId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        directMessageId
        groupMemberId
        content
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const getTerm = /* GraphQL */ `
  query GetTerm($id: ID!) {
    getTerm(id: $id) {
      id
      content
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
