import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { MenuDrawerComponent } from '../menu-drawer/menu-drawer.component'
import { PopoverComponent } from '../popover/popover.component'

@NgModule({
  declarations: [
    MenuDrawerComponent,
    PopoverComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    MenuDrawerComponent,
    PopoverComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
