/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-northeast-1",
  "aws_content_delivery_bucket": "ourconeweb-hostingbucket-prd",
  "aws_content_delivery_bucket_region": "ap-northeast-1",
  "aws_content_delivery_url": "https://d1a1wbyk2bpit6.cloudfront.net",
  "aws_appsync_graphqlEndpoint": "https://xvduzed4qjgltbseedqun5ikbi.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  "aws_appsync_region": "ap-northeast-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_identity_pool_id": "ap-northeast-1:65d5bfce-8f79-4786-9916-39aa01f9204a",
  "aws_cognito_region": "ap-northeast-1",
  "aws_user_pools_id": "ap-northeast-1_TQrJrCHSm",
  "aws_user_pools_web_client_id": "q16p83f01kuf8r80kka74vemu",
  "aws_user_files_s3_bucket": "ourconestorage182722-prd",
  "aws_user_files_s3_bucket_region": "ap-northeast-1",
  "aws_cloud_logic_custom": [
    {
      "name": "ourconePublicAPI",
      "endpoint": "https://so1aurene2.execute-api.ap-northeast-1.amazonaws.com/dev",
      "region": "ap-northeast-1"
    }
  ]
};


export default awsmobile;
