// エラーメッセージ
export interface ErrorMessageInterface {
    readonly REQUIRED: string; // 必須
    readonly SELECT_REQUIRED: string; // 必須(選択)
    readonly MAX_LENGTH: string; // 文字数上限
    readonly MIN_LENGTH: string; // 文字数下限
    readonly EMAIL: string; // メールアドレス
    readonly EMAIL_DUPLICATION: string; // メールアドレス重複
    readonly EXPIRATION: string; // 有効期限切れ
    readonly DUPLICATION: string; // 重複
    readonly ERROR: string; // 誤り
    readonly ERROR_LOGIN: string; // 誤り
    readonly DELETE: string; // 削除
    readonly SIX_DIGIT: string; // 6桁数字
    readonly ALPHA_NUMERIC: string; // 半角
    readonly GROUP_MEMBER_MAX: string; // 最大グループメンバー数上限
    readonly TEAM_MAX: string; // 最大チーム数上限
}

export const ErrorMessage: ErrorMessageInterface = {
    REQUIRED: "を入力してください。",
    SELECT_REQUIRED: "を選択してください。",
    MAX_LENGTH: "文字以下で入力してください。",
    MIN_LENGTH: "文字以上で入力してください。",
    EMAIL: "メールアドレスの形式が正しくありません。",
    EMAIL_DUPLICATION: "入力したメールアドレスはすでに登録済みです。",
    EXPIRATION: "有効期限切れです。",
    DUPLICATION: "すでに登録済みです。",
    ERROR: "入力した値に誤りがあります。",
    ERROR_LOGIN: "入力したIDまたはメールアドレスに誤りがあります。",
    DELETE: "削除できません。",
    SIX_DIGIT: "6桁の数字で入力してください。",
    ALPHA_NUMERIC: "半角英数、半角「-」記号で入力してください。",
    GROUP_MEMBER_MAX: "グループメンバーの登録上限は1000名です。",
    TEAM_MAX: "チームの最大件数を超えてます。"
}

// パターン（正規表現式）
export interface ErrorPatternInterface {
    readonly EMAIL: string; // メールアドレス
    readonly SIX_DIGIT: string; // 数字のみ （編集済み）
    readonly ALPHA_NUMERIC: string; // 半額英数 ＋ 記号「-」のみ
}

export const ErrorPattern: ErrorPatternInterface = {
    EMAIL: "^[a-zA-Z0-9_#!$%&`'*+-{|}~^\/=?.]+@([a-zA-Z0-9-]+.)+([a-z]+)$", // メールアドレスフォーマット
    SIX_DIGIT: "[0-9]{6}",
    ALPHA_NUMERIC: "^[a-zA-Z0-9-]+$", // 半額英語 ＋ 記号「-」のみ
}
