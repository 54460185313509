import { Injectable } from '@angular/core';
import { Auth } from "aws-amplify";
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import * as Query from "../graphql/queries";
import API, {graphqlOperation} from "@aws-amplify/api";
// import {ApiProvider} from "./api";
@Injectable({
  providedIn: 'root'
})
export class AuthProvider {

  constructor(
    private router: Router,
    // private APIPrv: ApiProvider,
  ) {
  }

  async signUp(email): Promise<any> {

    let uuid = uuidv4();
    let password = uuidv4();
    await Auth.signUp(
      {
        username: uuid,
        password: password,
        attributes: {email: email }
      }
    );
    return uuid;
  }

  async customChallenge(cognitoUser, code): Promise<Object> {
    return await Auth.sendCustomChallengeAnswer(cognitoUser, code);
  }

  async login(email): Promise<Object> {
    return await Auth.signIn(email);
  }

  // ユーザーチェック
  async getUser(id) {
//参照循環になるので　ApiProvider　は使用しない
// ERROR Error: NG0200: Circular dependency in DI detected for AuthProvider

    const corporateId = 'front';
    const wafHeader = {'X-CorporateId':　corporateId};
    let user = await API.graphql(graphqlOperation(Query.getUser, {id: id}), wafHeader);
    const userData = user["data"]["getUser"]
    console.log(userData);
    return userData;
  }

  async logout(): Promise<void> {
    Auth.signOut();
  }

  async loginCheck(): Promise<string> {
    //ログインチェック
    try{
      let currentSession = JSON.parse(JSON.stringify(await Auth.currentSession()));
      return currentSession.accessToken.payload.username;

    }catch{
      console.log("error auth");
    }
  }


  async registTel(tel): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    var result = await Auth.updateUserAttributes(
      user,
      {
        phone_number: tel // 新しい電話番号
      }
    );

    console.log(result);


    await Auth.verifyCurrentUserAttribute(
      'phone_number' // メールアドレスまたは電話番号
    );
  }

  async confirmTel(code): Promise<any> {
    await Auth.verifyCurrentUserAttributeSubmit(
      'phone_number',
      code // 認証コード
    );
  }

  async getCognitoIdentityId(): Promise<string> {
    try{
      let credentials = JSON.parse(JSON.stringify(await Auth.currentUserCredentials()));
      return credentials.identityId;

    }catch{
      console.log("error auth");
    }
  }

  async getAccessToken(): Promise<any> {
    return (await Auth.currentSession()).getIdToken()
  }

}
