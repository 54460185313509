import { Injectable, NgZone } from '@angular/core';
import {AlertController, isPlatform} from '@ionic/angular';
import { Router } from '@angular/router';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import {ApiProvider} from "../providers/api";

import { StorageService } from "../app/services/storage.service";
import * as Mutation from "../graphql/mutations";
// const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PushNoticeProvider {

  user: any = {}
  constructor(
    public storageService: StorageService,
    private router: Router,
    private alertCtrl: AlertController,
    private ngZone: NgZone,
    private APIPrv: ApiProvider,
  ) { }



  pushRegister() {
    console.log('Initializing pushRegister');


    if (isPlatform('ios') || isPlatform('android')) {
      console.log('Start Token Logic');


      PushNotifications.requestPermissions().then(result => {
        if (result.receive) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          console.log('success regist push notice.');
        } else {
          console.log('fail to  regist push notice.');
        }
      });


      // On success, we should be able to receive notifications
      PushNotifications.addListener(
        'registration',
        async (token: Token) => {
          console.log("Push registration success, push token", token.value);

          let userDeviceId = await this.storageService.getUserDeviceId()
          const deviceToken = await this.storageService.getDeviceToken()
          //if(token && token.value !== deviceToken) {

          await this.storageService.setDeviceToken(token.value)
          const userId = await this.storageService.getUserId();
          // console.log(`userId ${userId}`);
          let kind = "";
          if (isPlatform('ios')) kind = "ios";
          if (isPlatform('android')) kind = "android";

          console.log(userDeviceId);
          if (userId) {
            const input = {
              userDeviceId: userDeviceId,
              userId: userId,
              kind: kind,
              deviceToken: token.value,
            }
            console.log(JSON.stringify(input));
            await this.APIPrv.graphql(Mutation.createPushEndpointApi, input);
          }
          const currentTimeStamp = new Date().toISOString();
          await this.storageService.setInitPushTime(currentTimeStamp)
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      });

      // もしアプリが開いてる状態で通知を受け取ったら
      PushNotifications.addListener(
        'pushNotificationReceived',
        async (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));

          if (await this.storageService.getLoginStatus()) {
            //Android
            if (isPlatform('android')) {
              let result;
              const alert = await this.alertCtrl.create({
                header: notification.data.title,
                message: notification.data.body,
                buttons: [{
                  text: 'OK',
                  handler: () => result = true,
                }],
              });
              //console.log('alert: ' + JSON.stringify(alert));

              await alert.present();
              await alert.onWillDismiss();
              //console.log('result: ' + JSON.stringify(result));

              if (result) {
                const metadata = JSON.parse(notification.data.metadata);
                if (metadata.redirectParams) {
                  //console.log('redirect: ' + JSON.stringify(metadata));
                  await PushNotifications.removeDeliveredNotifications(
                    await PushNotifications.getDeliveredNotifications()
                  );
                  //console.log('clear: ');
                  await this.ngZone.run(async () => {
                    await this.router.navigateByUrl(metadata.redirectParams);
                  });
                }
              }
            }

          } else {
            await this.router.navigateByUrl("/home");
          }


        }
      );

      // 通知がタップされた時に発動する
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        async (notification: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));

          if (await this.storageService.getLoginStatus()) {

            //アプリがKILL時は用意ができるまで待つ
            const sleepFunc = (m) => {
              return new Promise((resolve) => setTimeout(resolve, m));
            };
            for(let i=0; i< 50; i++){
              await sleepFunc(500);
              if(await this.storageService.getInitPushTime()) break;
            }

            const metadata = JSON.parse(notification.notification.data.metadata);
            if (metadata.redirectParams) {
              await PushNotifications.removeAllDeliveredNotifications();
              await this.ngZone.run(async () => {
                await this.router.navigateByUrl(metadata.redirectParams);
              });
            }

          } else {
            await this.router.navigateByUrl("/home");
          }

        }
      );
      if (isPlatform('android')) {
        console.log("createChannel")
        PushNotifications.createChannel(
          {
            id: "ourcone_id", // (required)
            name: "Ourcone", // (required)
            vibration: true,
            importance: 4
          }
        ).then(res => {
          console.log(JSON.stringify(res))
        })
          .catch(error => {
            console.log(JSON.stringify(error))
          });
      }


    } else {
      console.log('This is Web');
    }

  }

  clearRegister() {
    PushNotifications.removeAllListeners().then(()=>{console.log("Remeved PushNotifications")})
  }

  clearNotifications() {
    PushNotifications.removeAllDeliveredNotifications().then(()=>{console.log("Remeved DeliveredNotifications")})
  }


}
