export const AppPath = {
    MAILING_DETAIL: 'mailing-detail',
    DIRECT_MESSAGE: 'direct-message-chat'
}

export const AppInfo = {
    DEV: {
        APP_NAME: "ourconedev",
        APP_DOMAIN: "dev.ourcone.com"
    },
    STG: {
        APP_NAME: "ourconestg",
        APP_DOMAIN: "stg.ourcone.com"
    },
    PRD: {
        APP_NAME: "ourcone",
        APP_DOMAIN: "ourcone.com"
    },
}