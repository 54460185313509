import { Component, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AppPath } from './const/app-scheme';

const MAILING_DETAIL = `/${AppPath.MAILING_DETAIL}/`;
const DIRECT_MESSAGE = `/${AppPath.DIRECT_MESSAGE}/`;

const APP_SCHEME_FLAG = 'appSchemeFlag'
const LOGIN_STATUS = 'loginStatus';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private storage: Storage,
    private platform: Platform,
    private router: Router,
    private zone: NgZone
  ) {
    this.storage.create();
    this.platform.ready().then(() => {
      // IOSの場合、StatusBarのStyleをLightにする
      if (this.platform.is('ios')) {
        StatusBar.setStyle({ style: Style.Light });
      }
      
      // スマホの戻るボタンの無効化
      this.platform.backButton.subscribeWithPriority(150, (processNextHandler) => {
        // console.log('Back press handler!');
      });

      this.initializeApp();
    });
  }

  initializeApp() {
    // App Schemeイベント処理
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(async () => {
          // App Scheme URL 例）ourcone://mailing?page=abcd12-1234-abcd-1234ab
          const url = event.url;

          const loginStatus = await this.storage.get(LOGIN_STATUS);

          // ログインユーザーの場合
          if (loginStatus) {
            this.storage.set(APP_SCHEME_FLAG, true);

            if (url.indexOf(MAILING_DETAIL) > 0) {
              let id = url.split(MAILING_DETAIL).pop();
              this.router.navigateByUrl(`${MAILING_DETAIL}${id}`);
            } else if (url.indexOf(DIRECT_MESSAGE) > 0) {            
              let id = url.split(DIRECT_MESSAGE).pop();
              this.router.navigateByUrl(`${DIRECT_MESSAGE}${id}`);
            } else {
              this.storage.set(APP_SCHEME_FLAG, false);
            }
  
            setTimeout(() => {
              // アプリ起動して10秒過ぎたらAppSchemeFlagをFlaseにする
              this.storage.set(APP_SCHEME_FLAG, false);
            }, 10000);
          } else {
            this.storage.set(APP_SCHEME_FLAG, false);
          }
        });
    });
  }
}
