import {AlertController} from '@ionic/angular';
import {Network} from '@capacitor/network';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  status: any;
  listener: any;

  constructor(
    private alertCtrl: AlertController
  ) {
  }

  // ネット接続の状態を取得
  async getStatus() {
    try {
      this.status = await Network.getStatus();
      if (this.status.connected === false) {
        await this.networkAlert();
      }
      return this.status.connected;
    } catch (error) {
      console.error(error);
    }
  }

  // ネット接続の確認を開始
  async startListenNetwork() {
    await Network.removeAllListeners();
    this.listener = Network.addListener('networkStatusChange', async (status) => {
      if (!status.connected) {
        await this.networkAlert();
      }
    });
  }

// ネット接続の確認を終了
  stopListenNetwork() {
    if (this.listener) {
      this.listener.remove();
    }
  }

  async networkAlert() {
    const alert = await this.alertCtrl.create({
      header: 'ネットワークエラー',
      message: 'ネットワークの接続に失敗しました。',
      buttons: [{
        text: 'OK'
      }]
    });
    await alert.present();
  }
}
