/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestionAnswerApi = /* GraphQL */ `
  mutation CreateQuestionAnswerApi(
    $questionDetailId: ID!
    $groupMemberId: ID!
  ) {
    createQuestionAnswerApi(
      questionDetailId: $questionDetailId
      groupMemberId: $groupMemberId
    )
  }
`;
export const deleteOldTopicApi = /* GraphQL */ `
  mutation DeleteOldTopicApi($teamIdList: [ID!]!) {
    deleteOldTopicApi(teamIdList: $teamIdList)
  }
`;
export const createTopicApi = /* GraphQL */ `
  mutation CreateTopicApi(
    $groupMemberId: String!
    $selectedTeamList: [String!]!
    $input: TopicInput!
    $uploadList: [TopicFileInput]
    $questionList: [QuestionDetailInput]
    $questionDeadline: String
  ) {
    createTopicApi(
      groupMemberId: $groupMemberId
      selectedTeamList: $selectedTeamList
      input: $input
      uploadList: $uploadList
      questionList: $questionList
      questionDeadline: $questionDeadline
    )
  }
`;
export const deleteDirectMessageApi = /* GraphQL */ `
  mutation DeleteDirectMessageApi($directMessageId: String!) {
    deleteDirectMessageApi(directMessageId: $directMessageId)
  }
`;
export const updateServiceAdminApi = /* GraphQL */ `
  mutation UpdateServiceAdminApi(
    $serviceAdminId: ID!
    $userId: ID!
    $name: String!
    $email: String!
  ) {
    updateServiceAdminApi(
      serviceAdminId: $serviceAdminId
      userId: $userId
      name: $name
      email: $email
    )
  }
`;
export const updateCorporateAdminApi = /* GraphQL */ `
  mutation UpdateCorporateAdminApi(
    $corporateAdminId: ID!
    $userId: ID!
    $name: String!
    $email: String!
    $phoneNumber: String
    $logoutFlag: Int
  ) {
    updateCorporateAdminApi(
      corporateAdminId: $corporateAdminId
      userId: $userId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      logoutFlag: $logoutFlag
    )
  }
`;
export const createGroupApi = /* GraphQL */ `
  mutation CreateGroupApi(
    $groupId: ID!
    $corporateId: ID!
    $serviceId: ID!
    $name: String!
    $passcode: String!
    $usePasscode: String!
    $status: Int!
    $displayAllMember: Int
    $topicRole: Int
  ) {
    createGroupApi(
      groupId: $groupId
      corporateId: $corporateId
      serviceId: $serviceId
      name: $name
      passcode: $passcode
      usePasscode: $usePasscode
      status: $status
      displayAllMember: $displayAllMember
      topicRole: $topicRole
    )
  }
`;
export const createGroupMemberApi = /* GraphQL */ `
  mutation CreateGroupMemberApi(
    $name: String!
    $email: String!
    $groupId: ID!
    $role: String!
    $memberCode: String
    $serviceId: String
  ) {
    createGroupMemberApi(
      name: $name
      email: $email
      groupId: $groupId
      role: $role
      memberCode: $memberCode
      serviceId: $serviceId
    )
  }
`;
export const updateGroupMemberApi = /* GraphQL */ `
  mutation UpdateGroupMemberApi(
    $groupMemberId: ID!
    $userId: ID!
    $memberCode: String
    $name: String!
    $email: String
    $role: Int!
  ) {
    updateGroupMemberApi(
      groupMemberId: $groupMemberId
      userId: $userId
      memberCode: $memberCode
      name: $name
      email: $email
      role: $role
    )
  }
`;
export const createTeamApi = /* GraphQL */ `
  mutation CreateTeamApi(
    $groupId: ID!
    $serviceId: ID
    $name: String!
    $memberCount: Int
    $isDefault: Int!
  ) {
    createTeamApi(
      groupId: $groupId
      serviceId: $serviceId
      name: $name
      memberCount: $memberCount
      isDefault: $isDefault
    )
  }
`;
export const updateTeamApi = /* GraphQL */ `
  mutation UpdateTeamApi($id: ID!, $name: String!) {
    updateTeamApi(id: $id, name: $name)
  }
`;
export const createTeamMemberApi = /* GraphQL */ `
  mutation CreateTeamMemberApi(
    $teamId: ID!
    $groupId: String!
    $teamMembers: [CreateTeamMemberApiInput!]!
  ) {
    createTeamMemberApi(
      teamId: $teamId
      groupId: $groupId
      teamMembers: $teamMembers
    )
  }
`;
export const createBulkGroupMembersApi = /* GraphQL */ `
  mutation CreateBulkGroupMembersApi(
    $serviceId: ID
    $groupId: String!
    $groupMembers: [CreateGroupMemberApiInput!]!
  ) {
    createBulkGroupMembersApi(
      serviceId: $serviceId
      groupId: $groupId
      groupMembers: $groupMembers
    )
  }
`;
export const deleteCorporateAdminApi = /* GraphQL */ `
  mutation DeleteCorporateAdminApi($corporateAdminId: ID!) {
    deleteCorporateAdminApi(corporateAdminId: $corporateAdminId)
  }
`;
export const deleteServiceAdminApi = /* GraphQL */ `
  mutation DeleteServiceAdminApi($serviceAdminId: ID!) {
    deleteServiceAdminApi(serviceAdminId: $serviceAdminId)
  }
`;
export const deleteGroupApi = /* GraphQL */ `
  mutation DeleteGroupApi($groupId: ID!) {
    deleteGroupApi(groupId: $groupId)
  }
`;
export const deleteGroupMemberApi = /* GraphQL */ `
  mutation DeleteGroupMemberApi($groupMemberId: ID!) {
    deleteGroupMemberApi(groupMemberId: $groupMemberId)
  }
`;
export const deleteTeamApi = /* GraphQL */ `
  mutation DeleteTeamApi($teamId: ID!) {
    deleteTeamApi(teamId: $teamId)
  }
`;
export const deleteTeamMemberApi = /* GraphQL */ `
  mutation DeleteTeamMemberApi($teamMembers: [DeleteTeamMemberApiInput]) {
    deleteTeamMemberApi(teamMembers: $teamMembers)
  }
`;
export const createPushEndpointApi = /* GraphQL */ `
  mutation CreatePushEndpointApi(
    $userDeviceId: ID!
    $userId: ID!
    $kind: String
    $deviceToken: String!
  ) {
    createPushEndpointApi(
      userDeviceId: $userDeviceId
      userId: $userId
      kind: $kind
      deviceToken: $deviceToken
    )
  }
`;
export const pushNotificationApi = /* GraphQL */ `
  mutation PushNotificationApi($userIdList: [ID!]!, $message: String!) {
    pushNotificationApi(userIdList: $userIdList, message: $message)
  }
`;
export const sendEmailApi = /* GraphQL */ `
  mutation SendEmailApi($type: Int!, $id: ID!) {
    sendEmailApi(type: $type, id: $id)
  }
`;
export const topicRemindApi = /* GraphQL */ `
  mutation TopicRemindApi($topicId: ID!, $remindFlag: RemindFlagInput!) {
    topicRemindApi(topicId: $topicId, remindFlag: $remindFlag)
  }
`;
export const sendDirectMessage = /* GraphQL */ `
  mutation SendDirectMessage(
    $directMessageId: ID!
    $groupMemberId: ID!
    $content: String
  ) {
    sendDirectMessage(
      directMessageId: $directMessageId
      groupMemberId: $groupMemberId
      content: $content
    )
  }
`;
export const createTroubleReportApi = /* GraphQL */ `
  mutation CreateTroubleReportApi(
    $teamId: ID!
    $groupMemberId: ID!
    $content: String
  ) {
    createTroubleReportApi(
      teamId: $teamId
      groupMemberId: $groupMemberId
      content: $content
    )
  }
`;
export const createTopicReplyApi = /* GraphQL */ `
  mutation CreateTopicReplyApi(
    $topicId: ID!
    $groupMemberId: ID!
    $content: String
  ) {
    createTopicReplyApi(
      topicId: $topicId
      groupMemberId: $groupMemberId
      content: $content
    )
  }
`;
export const sendSMSApi = /* GraphQL */ `
  mutation SendSMSApi($corporateId: ID!, $type: Int!, $email: String) {
    sendSMSApi(corporateId: $corporateId, type: $type, email: $email)
  }
`;
export const deleteUserDevice = /* GraphQL */ `
  mutation DeleteUserDevice(
    $input: DeleteUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    deleteUserDevice(input: $input, condition: $condition) {
      id
      kind
      userId
      deviceToken
      endpointArn
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const createTroubleReport = /* GraphQL */ `
  mutation CreateTroubleReport(
    $input: CreateTroubleReportInput!
    $condition: ModelTroubleReportConditionInput
  ) {
    createTroubleReport(input: $input, condition: $condition) {
      id
      teamId
      groupMemberId
      content
      createdAt
      updatedAt
    }
  }
`;
export const createInquiry = /* GraphQL */ `
  mutation CreateInquiry(
    $input: CreateInquiryInput!
    $condition: ModelInquiryConditionInput
  ) {
    createInquiry(input: $input, condition: $condition) {
      id
      groupMemberId
      name
      email
      content
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      termsAgreedAt
      lastUpdatedAt
      createdAt
      updatedAt
      groupMembers {
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      termsAgreedAt
      lastUpdatedAt
      createdAt
      updatedAt
      groupMembers {
        nextToken
      }
    }
  }
`;
export const createServiceAdmin = /* GraphQL */ `
  mutation CreateServiceAdmin(
    $input: CreateServiceAdminInput!
    $condition: ModelServiceAdminConditionInput
  ) {
    createServiceAdmin(input: $input, condition: $condition) {
      id
      name
      email
      serviceId
      isProtected
      createdAt
      updatedAt
    }
  }
`;
export const updateServiceAdmin = /* GraphQL */ `
  mutation UpdateServiceAdmin(
    $input: UpdateServiceAdminInput!
    $condition: ModelServiceAdminConditionInput
  ) {
    updateServiceAdmin(input: $input, condition: $condition) {
      id
      name
      email
      serviceId
      isProtected
      createdAt
      updatedAt
    }
  }
`;
export const createCorporate = /* GraphQL */ `
  mutation CreateCorporate(
    $input: CreateCorporateInput!
    $condition: ModelCorporateConditionInput
  ) {
    createCorporate(input: $input, condition: $condition) {
      id
      name
      groupCount
      email
      isAlive
      serviceId
      passcode
      passcodeUpdated
      createdAt
      updatedAt
      groups {
        nextToken
      }
      corporateAdmins {
        nextToken
      }
    }
  }
`;
export const updateCorporate = /* GraphQL */ `
  mutation UpdateCorporate(
    $input: UpdateCorporateInput!
    $condition: ModelCorporateConditionInput
  ) {
    updateCorporate(input: $input, condition: $condition) {
      id
      name
      groupCount
      email
      isAlive
      serviceId
      passcode
      passcodeUpdated
      createdAt
      updatedAt
      groups {
        nextToken
      }
      corporateAdmins {
        nextToken
      }
    }
  }
`;
export const createCorporateAdmin = /* GraphQL */ `
  mutation CreateCorporateAdmin(
    $input: CreateCorporateAdminInput!
    $condition: ModelCorporateAdminConditionInput
  ) {
    createCorporateAdmin(input: $input, condition: $condition) {
      id
      corporateId
      name
      email
      phoneNumber
      logoutFlag
      createdAt
      updatedAt
      corporate {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCorporateAdmin = /* GraphQL */ `
  mutation UpdateCorporateAdmin(
    $input: UpdateCorporateAdminInput!
    $condition: ModelCorporateAdminConditionInput
  ) {
    updateCorporateAdmin(input: $input, condition: $condition) {
      id
      corporateId
      name
      email
      phoneNumber
      logoutFlag
      createdAt
      updatedAt
      corporate {
        id
        name
        groupCount
        email
        isAlive
        serviceId
        passcode
        passcodeUpdated
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      corporateId
      name
      usePasscode
      passcode
      teamCount
      memberCount
      memberPauseCount
      status
      serviceId
      displayAllMember
      topicRole
      createdAt
      updatedAt
      groupMembers {
        nextToken
      }
      teams {
        nextToken
      }
    }
  }
`;
export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      userId
      groupId
      name
      email
      role
      memberCode
      joinedTeamCount
      joinedTeams {
        teamId
        teamName
      }
      badge {
        memberId
        topicUnread
        topicUnreadCount
        topicDmUnread
        topicDmUnreadCount
        topicAnnounceUnread
        topicAnnounceUnreadCount
        topicUnanswered
        topicUnansweredCount
        adminGroupHasPreMembers
        adminGroupHasPreMembersCount
      }
      emailNotify
      pushNotify
      termsAgreedAt
      serviceId
      createdAt
      updatedAt
      user {
        id
        email
        termsAgreedAt
        lastUpdatedAt
        createdAt
        updatedAt
      }
      group {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
      reactions {
        nextToken
      }
      topics {
        nextToken
      }
      topicReplies {
        nextToken
      }
      teamMembers {
        nextToken
      }
      directMessages {
        nextToken
      }
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      groupId
      isDefault
      name
      memberCount
      serviceId
      createdAt
      updatedAt
      teamMembers {
        nextToken
      }
      topics {
        nextToken
      }
      reactions {
        nextToken
      }
      group {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      groupId
      isDefault
      name
      memberCount
      serviceId
      createdAt
      updatedAt
      teamMembers {
        nextToken
      }
      topics {
        nextToken
      }
      reactions {
        nextToken
      }
      group {
        id
        corporateId
        name
        usePasscode
        passcode
        teamCount
        memberCount
        memberPauseCount
        status
        serviceId
        displayAllMember
        topicRole
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      userId
      groupMemberId
      teamId
      name
      email
      memberCode
      teamNotify
      teamView
      createdAt
      updatedAt
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      team {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      creator
      teamId
      groupMemberId
      title
      content
      isQuestion
      answerMembersCount
      questionDeadline
      destinationMembersCount
      alreadyReadMembersCount
      attached
      attachedFiles {
        id
        contentType
        fileName
        fileSize
        downloadUrl
        downloadThumbnailUrl
        uploadUrl
      }
      createdAt
      updatedAt
      questionDeadline
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      team {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
      topicReplies {
        nextToken
      }
      reactions {
        nextToken
      }
      questionDetails {
        nextToken
      }
    }
  }
`;
export const updateTopicReply = /* GraphQL */ `
  mutation UpdateTopicReply(
    $input: UpdateTopicReplyInput!
    $condition: ModelTopicReplyConditionInput
  ) {
    updateTopicReply(input: $input, condition: $condition) {
      id
      creator
      topicId
      groupMemberId
      content
      createdAt
      updatedAt
      topic {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateReaction = /* GraphQL */ `
  mutation UpdateReaction(
    $input: UpdateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    updateReaction(input: $input, condition: $condition) {
      id
      owner
      topicId
      groupMemberId
      teamId
      dispOnHome
      read
      answered
      answeredSorter
      answeredAt
      createdAt
      updatedAt
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      topic {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      team {
        id
        groupId
        isDefault
        name
        memberCount
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateQuestionDetail = /* GraphQL */ `
  mutation UpdateQuestionDetail(
    $input: UpdateQuestionDetailInput!
    $condition: ModelQuestionDetailConditionInput
  ) {
    updateQuestionDetail(input: $input, condition: $condition) {
      id
      creator
      topicId
      questionNo
      content
      answerCount
      topicCreator
      createdAt
      updatedAt
      topic {
        id
        creator
        teamId
        groupMemberId
        title
        content
        isQuestion
        answerMembersCount
        questionDeadline
        destinationMembersCount
        alreadyReadMembersCount
        attached
        createdAt
        updatedAt
      }
      questionAnswers {
        nextToken
      }
    }
  }
`;
export const deleteQuestionAnswer = /* GraphQL */ `
  mutation DeleteQuestionAnswer(
    $input: DeleteQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    deleteQuestionAnswer(input: $input, condition: $condition) {
      id
      owner
      questionDetailId
      topicId
      groupMemberId
      createdAt
      updatedAt
      questionDetail {
        id
        creator
        topicId
        questionNo
        content
        answerCount
        topicCreator
        createdAt
        updatedAt
      }
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createDirectMessage = /* GraphQL */ `
  mutation CreateDirectMessage(
    $input: CreateDirectMessageInput!
    $condition: ModelDirectMessageConditionInput
  ) {
    createDirectMessage(input: $input, condition: $condition) {
      id
      groupMemberId
      toGroupMemberId
      toName
      timestamp
      read
      toRead
      owners
      createdAt
      updatedAt
      directMessageComments {
        nextToken
      }
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      toGroupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateDirectMessage = /* GraphQL */ `
  mutation UpdateDirectMessage(
    $input: UpdateDirectMessageInput!
    $condition: ModelDirectMessageConditionInput
  ) {
    updateDirectMessage(input: $input, condition: $condition) {
      id
      groupMemberId
      toGroupMemberId
      toName
      timestamp
      read
      toRead
      owners
      createdAt
      updatedAt
      directMessageComments {
        nextToken
      }
      groupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
      toGroupMember {
        id
        userId
        groupId
        name
        email
        role
        memberCode
        joinedTeamCount
        emailNotify
        pushNotify
        termsAgreedAt
        serviceId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createDirectMessageComment = /* GraphQL */ `
  mutation CreateDirectMessageComment(
    $input: CreateDirectMessageCommentInput!
    $condition: ModelDirectMessageCommentConditionInput
  ) {
    createDirectMessageComment(input: $input, condition: $condition) {
      id
      directMessageId
      groupMemberId
      content
      owners
      createdAt
      updatedAt
      directMessage {
        id
        groupMemberId
        toGroupMemberId
        toName
        timestamp
        read
        toRead
        owners
        createdAt
        updatedAt
      }
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const createTerm = /* GraphQL */ `
  mutation CreateTerm(
    $input: CreateTermInput!
    $condition: ModelTermConditionInput
  ) {
    createTerm(input: $input, condition: $condition) {
      id
      content
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTerm = /* GraphQL */ `
  mutation UpdateTerm(
    $input: UpdateTermInput!
    $condition: ModelTermConditionInput
  ) {
    updateTerm(input: $input, condition: $condition) {
      id
      content
      lastUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
